import './App.css';
import React from 'react';
import AppRouter from './router';
import Navbar from './components/navbar';
import 'leaflet/dist/leaflet.css';
// import { BrowserRouter } from 'react-router-dom';
// import HomePage from './pages/homepage';

function App() {
  return (
    // <HomePage/>
      // <BrowserRouter>
        <AppRouter/>  
        
      // </BrowserRouter>
  );
}

export default App;
